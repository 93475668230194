.table-container {
  overflow-x: auto;
  width: 100%; /* Set a fixed width for the container */
}

.table-section {
  margin-bottom: 30px;
}

.table-heading-item {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 23px;
}

.table-heading-item .sort-icons {
  display: flex;
  flex-flow: column;
  position: absolute;
  right: 00;
}
table.material-table thead th .table-heading-item svg {
  top: 0;
}

.table-heading-item .sort-icons svg {
  width: 14px;
  height: 14px;
}

.table-heading-item .sort-icons > svg {
  margin: 0;
}

.table-filters {
  display: flex;
  align-items: center;
  gap: 19.5px;
  width: 100%;
  max-width: 600px;
  justify-content: flex-end;
}

.table-filters select {
  width: auto;
  padding-right: 14px;
  border: 0;
  background: transparent;
  outline: none;
  font-family: "Aeonik";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.77px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-left: 5px;
}

table.material-table thead th.existing_brand-action {
  width: 12%;
  text-align: center;
}
.table-heading-item .sort-icons .active {
  color: #000000;
}

.material-table td .action-buttons.exist-brand button.view-button {
  border: 0;
}
.material-table td .action-buttons.exist-brand button.view-button:disabled,
button.delete-button:disabled {
  cursor: not-allowed;
}
.action-buttons.exist-brand {
  justify-content: center;
}

.dropdown .chakra-select__wrapper {
  width: auto;
}

.dropdown .chakra-select__wrapper .chakra-select__icon-wrapper {
  display: none;
}

.table-filters .dropdown {
  height: auto;
  margin: 0;
  display: flex;
  gap: 12px;
  align-items: baseline;
}

.table-filters .dropdown label {
  font-family: "Aeonik";
  font-size: 14px;
  font-weight: 700;
  line-height: 16.77px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000000;
}

.table-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 23px;
}

.table-header-content input[type="text"] {
  margin: 0 !important;
}

.table-header-content input[type="text"] {
  border: 1px solid #ebebeb;
  width: 100%;
  max-width: 344px;
  height: 39px;
  padding: 13px 20px;
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.38px;
  letter-spacing: 0.01em;
  text-align: left;
  border-radius: 120px;
  color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M7.64089 7.63656L11 11M8.77778 4.88889C8.77778 7.03667 7.03667 8.77778 4.88889 8.77778C2.74112 8.77778 1 7.03667 1 4.88889C1 2.74112 2.74112 1 4.88889 1C7.03667 1 8.77778 2.74112 8.77778 4.88889Z' stroke='%23979797' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
}

.table-header-content input[type="text"]::placeholder {
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.38px;
  letter-spacing: 0.01em;
  text-align: left;
  opacity: 1;
  color: #000000;
}

.material-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  border-radius: 27px;
  border-collapse: unset;
  border: 1px solid #ddd !important;
  border-spacing: 0;
}

.version-container .material-table th {
  background: #141419;
}

.version-container .table-section {
  margin: 0 0 80px;
}

.container.version-container {
  margin-top: -33px;
}

.container.version-container .document-viewer {
  margin: 0;
}

.material-table th,
.material-table td {
  padding: 6px;
  text-align: left;
  white-space: normal;
  min-width: 150px; /* Increase the min-width value */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

table.material-table thead th {
  padding: 19px 26px 18px;
  font-family: "Aeonik";
  font-size: 17px;
  font-weight: 700;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: left;
  background: #4e6bf2;
  text-transform: capitalize;
}
table.material-table thead th svg {
  margin-right: 9px;
  position: relative;
  top: 3px;
}
table.material-table thead th table.material-table thead th:first-child {
  border-radius: 20px 0 0;
}

table.material-table thead th:last-child {
  border-radius: 0 20px 0 0;
}
.material-table th:nth-child(1),
.material-table td:nth-child(1) {
  width: 25%;
}

.material-table th:nth-child(2),
.material-table td:nth-child(2) {
  width: 24%;
}

.material-table th:nth-child(3),
.material-table td:nth-child(3) {
  width: 18%;
}

.material-table th:nth-child(4),
.material-table td:nth-child(4) {
  width: 22%;
}
.material-table tbody tr td {
  padding: 20px 26px;
  font-family: "Aeonik";
  font-size: 15px;
  font-weight: 400;
  line-height: 17.97px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000000;
  background: transparent;
}

.material-table tbody tr td {
  border: 0;
}
table.material-table {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
}
table tr td {
  border: 1px solid gray;
}

.material-table tbody tr td {
  border: 0;
  border-spacing: 0;
}
.material-table td .action-buttons button {
  margin: 0;
  padding: 0 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.material-table td .action-buttons button svg {
  line-height: 17px;
  color: #000000;
}

.material-table td .action-buttons button.delete-button {
  border: 1px solid #b4b4b4;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  border-left: 0;
}
.material-table th:nth-child(5),
.material-table td:nth-child(5) {
  width: 18%;
}

.material-table tr:nth-child(even) {
  background-color: rgb(237 237 237 / 50%);
}

.material-table tr:hover {
  background-color: #f5f5f5;
}

.material-table td {
  border-bottom: 1px solid #ddd;
}

.material-table td:last-child {
  border-right: none;
}

.action-column {
  text-align: right;
  white-space: nowrap;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.action-buttons button {
  margin-left: 8px;
  padding: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s;
}

.action-buttons .accept-button:hover,
.action-buttons .accept-button.active {
  color: #4caf50;
}

.action-buttons .reject-button:hover,
.action-buttons .reject-button.active {
  color: #f44336;
}

.action-buttons .neutral-button:hover,
.action-buttons .neutral-button.active {
  color: #4caf50;
}

.action-buttons button {
  background-color: transparent;
  border: none;
  color: #0056b3;
  cursor: pointer;
  margin-left: 8px;
  transition: color 0.3s;
}

.action-buttons .delete-button:hover {
  color: #ff0000;
}

.action-buttons .edit-button:hover {
  color: #008000;
}

.material-table tbody tr td {
  border: 0;
  border-spacing: 0;
}
.material-table td .action-buttons button {
  margin: 0;
  padding: 0 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.material-table td .action-buttons button svg {
  line-height: 17px;
  color: #000000;
}

.material-table td .action-buttons button.view-button {
  border: 1px solid #b4b4b4;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
}

.pagination {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.pagination > .page-number {
  margin: 0;
  background: transparent;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  line-height: 17.7px;
  text-align: left;
  color: #333333;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination > ul.page-number {
  display: flex;
  gap: 10px;
}

.pagination > .page-number li {
  list-style-type: none;
  margin: 0 5px 0 5px;
}

.pagination > .page-number.active {
  background: #2f80ed;
  border-color: #2f80ed;
  color: #fff;
}

.pre-page.disabled {
  background-color: rgb(181, 188, 194);
  pointer-events: none;
  fill: white;
}
.user-info {
  display: flex;
  align-items: center;
}

.upload-heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.file-drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px none #ccc;
  border-radius: 8px;
  padding: 2rem;
  transition: background-color 0.3s;
}

.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 2rem;
  transition: background-color 0.3s;
  height: 323px;
}

.document-viewer {
  margin: 0 0 60px;
}

.pre-mlr-review-header {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
  margin: 0 0 32px 0;
}

.document-viewer .file-drop {
  margin: 0;
}

.file-drop:hover {
  background-color: #f2f2f2;
}

.file-drop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-drop-icon img {
  width: 110px;
  height: 86px;
  margin-bottom: 30px;
}
.file-input {
  display: none;
}

.file-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-label:hover {
  background-color: transparent;
}

.file-label-text {
  margin-right: 0.5rem;
}

.pdf-viewer {
  max-height: 600px;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
}

.react-pdf__Page {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.suggestions-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.suggestions-section h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.suggestions-section p {
  margin-bottom: 10px;
}

.suggestions-section button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.suggestions-section button:hover {
  background-color: #0056b3;
}
.pagination > .page-number .active {
  background: #0056b3;
  border-color: #0056b3;
}
.page-count:hover,
.page-number > a:hover {
  background: #0056b3;
  border-color: #0056b3;
}

.page-count > a,
.page-number > a {
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
}

.page-number > a {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  background: transparent;
}
.pagination > .page-number li {
  background: transparent;
  margin: 0;
}

.page-number.disabled > a {
  background: #878787;
  cursor: not-allowed;
  border-color: #878787;
}

.page-number.disabled > a svg {
  color: #fff;
}
.page-number.active,
.page-count > a:hover,
.page-number > a:hover {
  background-color: #0056b3;
  color: white;
  border-color: #007bff;
}
.page-count {
  margin: 0px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}
.page-count.active {
  background-color: #2f80ed;
  color: #fff;
}
.page-count:hover {
  background-color: #2f80ed;
  color: #fff;
}
.pagination > .page-number svg {
  color: #333;
}
li.page-number:hover svg {
  color: #fff;
}



.export-button {
  display: inline-block;
  margin-left: 20px; /* Adjust the space between the heading and the button */
  padding: 5px 10px; /* Adjust padding for a smaller button */
  font-size: 14px; /* Adjust font size for a smaller button */
  background-color: #007bff; /* Adjust background color if needed */
  color: #fff; /* Adjust text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Optional: Add border radius */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex;
  align-items: center;
}

.export-button svg {
  margin-right: 5px; /* Space between the icon and the text */
}
.processing-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.webViewer {
  width: 100%;
  height: 600px;
  border: 1px solid #ccc;
}
.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 13px;
  margin: 10px 0;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 13px;
  margin: 10px 0;
  position: relative;
}

.progress-bar {
  height: 20px;
  background-color: #76c7c0;
  border-radius: 13px;
  transition: width 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-text {
  margin-left: 50px;
  color: white;
  font-weight: bold;
}

/* back btn */

.table-section.version-table {
  margin: 0 0 80px;
}

.container.version-container {
  margin-top: -10px;
}
button.gradient-btn {
  background: linear-gradient(
    90deg,
    rgba(78, 107, 244, 1) 0%,
    rgba(45, 62, 142, 1) 100%
  );
  height: 34px;
  border-radius: 50px;
  font-family: "Aeonik";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.77px;
  letter-spacing: 0.01em;
  text-align: left;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 39px;
  padding-right: 21px;
}

button.gradient-btn > span {
  width: 29px;
  height: 29px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  position: absolute;
  left: 2.5px;
  top: 50%;
  transform: translateY(-50%);
}

button.gradient-btn > span svg {
  color: #4a4a4a;
  width: 14px;
  height: 20px;
  stroke: transparent;
  fill: #4a4a4a;
  position: relative;
  left: -2px;
}

button.gradient-btn > span svg path {
  stroke: #fff;
}

.container.version-container button.back-btn {
  margin: 0 0 55px;
}

/* preview modal */

.content_container {
  position: relative;
  overflow: hidden;
}

.content_container.editor-open {
  padding: 87px 0 0;
}

.content_container.editor-open .container.version-container {
  margin: 0;
}

.content_container.editor-open .table-section {
  margin: 0 0 20.5px;
  padding: 0 49px;
}

.content_container.editor-open .table-section button.gradient-btn.back-btn {
  margin: 0;
}

.content_container.editor-open .version-details h3 {
  margin: 0;
}
.document-viewer.expend-viewer {
  margin: 0;
}

.document-viewer.expend-viewer .webViewer {
  height: calc(100vh - 197px) !important;
}

.pagination > ul.page-number {
  width: auto;
  height: auto;
  border: 0;
}

@media (max-width: 1900px) {
  .material-table th:nth-child(5),
  .material-table td:nth-child(5) {
    width: 190px;
  }
}

@media (max-width: 1700px) {
  .material-table th:nth-child(1),
  .material-table td:nth-child(1) {
    width: 21%;
  }
  .material-table th:nth-child(2),
  .material-table td:nth-child(2) {
    width: 17%;
  }
  .material-table th:nth-child(5),
  .material-table td:nth-child(5) {
    width: 150px;
  }
  .material-table th:nth-child(4),
  .material-table td:nth-child(4) {
    width: 30%;
  }
}

@media (max-width: 1535px) {
  .material-table th:nth-child(2),
  .material-table td:nth-child(2) {
    width: 18%;
  }
  table.material-table thead th {
    font-size: 15px;
  }
  .material-table th:nth-child(1),
  .material-table td:nth-child(1) {
    width: 21%;
  }
  .material-table th:nth-child(3),
  .material-table td:nth-child(3) {
    width: 18%;
  }
  .material-table th:nth-child(4),
  .material-table td:nth-child(4) {
    width: 28%;
  }

  .material-table th:nth-child(5),
  .material-table td:nth-child(5) {
    width: 150px;
  }
  table.material-table thead th {
    padding: 19px 15px 18px;
    font-size: 15px;
  }
  .material-table tbody tr td {
    padding: 20px 15px;
  }
}

@media (max-width: 1365px) {
  .container.version-container {
    margin-top: 0;
  }
  .content_container.editor-open .table-section {
    padding: 0 30px;
  }
}

@media (max-width: 1199px) {
  .table-header-content {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .table-filters {
    justify-content: space-between;
    max-width: 100%;
  }

  .table-header-content h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 15px;
    display: none;
  }
}
