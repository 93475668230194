.navbar {
  height: 100vh;
  width: 325px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #14141a;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 9px 0;
}
.navbar-header {
  text-align: center;
  margin-bottom: 33px;
  padding-bottom: 9px;
  border-bottom: 1px solid rgba(255, 255, 25, 0.1);
}
.navbar-logo {
  width: 100px;
  height: 100px;
}
.navbar-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}
.nav-item {
  margin-bottom: 10px;
}
.nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
}
.nav-link i,
.nav-link svg {
  margin-right: 10px;
}
.nav-link:hover {
  background-color: #333;
}

.navbar-nav li.nav-item {
  padding: 0 31px;
  margin: 0;
}

.navbar-nav li.nav-item > span.nav-link {
  font-family: "Aeonik";
  font-size: 18px;
  font-weight: 400;
  line-height: 21.56px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 18.5px 20px;
  background: transparent;
  cursor: pointer;
}

.navbar-nav li.nav-item > span.nav-link i {
  font-size: 18px;
}

.navbar-nav li.nav-item > span.nav-link:hover,
.navbar-nav li.nav-item > span.nav-link.active {
  color: #bfc9f4;
}

.navbar-nav li.nav-item > span.nav-link:hover svg path,
.navbar-nav li.nav-item > span.nav-link.active svg path {
  stroke: #bfc9f4;
}

.brand-select-container {
  padding: 10px 20px;
}
.brand-select {
  width: 100%;
  font-size: 12px;
}
.add-brand-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  font-size: 20px;
}
.add-brand-button:hover {
  background-color: #333;
}
.navbar-footer {
  margin-top: auto;
  text-align: center;
  padding-bottom: 20px;
}
.logout-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
}
.logout-button i {
  margin-right: 10px;
}
.logout-button:hover {
  background-color: #333;
}

.navbar:not(.navbar-open) {
  left: 0;
  width: 100px;
}

.navbar {
  transition: all 0.3s ease;
}

.navbar:not(.navbar-open) .navbar-header img {
  width: 50px;
  height: 50px;
}

.navbar * {
  transition: all 0.3s ease;
}

.navbar:not(.navbar-open) ul.navbar-nav li.nav-item {
  padding: 0;
}

.navbar:not(.navbar-open) ul.navbar-nav li.nav-item span.nav-link {
  font-size: 0;
  justify-content: center;
}

.navbar:not(.navbar-open) ul.navbar-nav li.nav-item span.nav-link svg {
  width: 18px;
  height: 18px;
  margin: 0;
}

.navbar:not(.navbar-open) ul.navbar-nav li.nav-item span.nav-link i {
  margin: 0;
}

.navbar:not(.navbar-open) ul.navbar-nav li.nav-item.brand-select-container {
  display: none;
}
.navbar:not(.navbar-open) ul.navbar-nav li.nav-item span.nav-link span {
  display: none;
}

@media (max-width: 1199px) {
  .navbar {
    width: 275px;
  }
}
