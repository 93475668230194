.container {
  padding: 20px;
}
.risk-tolerance-section {
  margin-bottom: 20px;
}

/* Add this CSS to your BrandRules.css file */

.risk-tolerance {
position: relative;
}

.risk-tolerance-slider-container {
display: flex;
align-items: center;
position: relative;
}

.risk-tolerance-slider-container input[type="range"] {
-webkit-appearance: none;
width: 100%;
height: 20px;
background: #ddd;
outline: none;
opacity: 0.7;
-webkit-transition: .2s;
transition: opacity .2s;
}

.risk-tolerance-slider-container input[type="range"]::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none;
width: 40px;
height: 40px;
background: #4c5eaf;
cursor: pointer;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
color: black;
font-size: 16px;
font-weight: bold;
}

.risk-tolerance-slider-container input[type="range"]::-moz-range-thumb {
width: 40px;
height: 40px;
background: #4CAF50;
cursor: pointer;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
color: black;
font-size: 16px;
font-weight: bold;
}

.risk-tolerance-slider-container .risk-tolerance-value {
position: absolute;
top: 50%;
transform: translate(-50%, -50%);
color: black;
font-size: 16px;
font-weight: bold;
}

.risk-tolerance-scale {
display: flex;
justify-content: space-between;
width: 100%;
}

.foundational-rule {
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
}

.expand-icon {
margin-left: 10px;
}


.foundational-rules {
  margin-top: 30px;
  background-color: #f0f0f0;  /* Light grey background */
  padding: 15px;
  border-radius: 5px;
}

.foundational-rule {
  background-color: #f5f5f5;  /* Light grey background */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.rule-item {
  margin-bottom: 10px;
}

.rule-item .rule-details {
  display: flex;
  flex-direction: column;
}

.rule-item .rule-actions {
  margin-top: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-rule-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.add-rule-button:hover {
  background-color: #0056b3;
}

.rules-list {
  margin-top: 20px;
}

.rule-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.rule-details {
  flex: 1;
}

.rule-actions {
  display: flex;
  align-items: center;
}

.delete-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.new-rule-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10001;
}

.new-rule-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.new-rule-modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.new-rule-modal label {
  display: flex;
  flex-direction: column;
}

.new-rule-modal input,
.new-rule-modal textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.new-rule-modal .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.new-rule-modal button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.new-rule-modal button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .new-rule-modal {
    width: 95%;
  }
}