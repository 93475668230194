.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -10px;
}

.file-type-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.radio-label {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  display: none;
}

.radio-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #0056b3;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
}

.radio-custom::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0056b3;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.radio-label input[type="radio"]:checked + .radio-custom::before {
  opacity: 1;
}

.radio-label-text {
  font-size: 16px;
  color: #333;
}

.file-drop {
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.file-drop-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-drop-zone .file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #000000;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-family: "Aeonik";
  font-size: 17px;
  font-weight: 400;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: center;
}

.file-label:hover {
  background: transparent;
}

.file-drop-zone .file-label .file-label-text {
  font-size: 14px; /* Adjust font size if needed */
  color: black; /* Ensure text color is consistent */
}

.file-drop-zone .file-label .no-file-chosen {
  font-size: 12px;
  color: #666; /* Slightly darker gray for "No file chosen" text */
}

input[type="file"] {
  display: none;
}
.table-section {
  margin-bottom: 30px;
}

.table-header {
  display: flex;
  justify-content: flex-start; /* Changed from space-between to flex-start */
  align-items: center;
  margin-bottom: 10px;
}
.table-header h3 {
  margin-right: 20px; /* Added margin-right for spacing */
}
.view-options button {
  background-color: transparent;
  border: none;
  color: #0056b3;
  padding: 8px 16px;
  cursor: pointer;
}

.view-options button:hover {
  background-color: #e3f2fd;
}

.search-container {
  margin-bottom: 10px;
}

.search-input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.material-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;
}
.material-table th,
.material-table td {
  padding: 15px;
  text-align: left;
}
.material-table th {
  background-color: #0056b3;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  padding: 15px;
}
.material-table th:nth-child(1),
.material-table td:nth-child(1) {
  width: 40%;
}
.material-table th:nth-child(2),
.material-table td:nth-child(2) {
  width: 30%;
}
.material-table th:nth-child(3),
.material-table td:nth-child(3) {
  width: 30%;
}

.material-table tr:hover {
  background-color: #e3f2fd;
}
.material-table td {
  border-bottom: 1px solid #ddd;
}
.material-table td:last-child {
  border-right: none;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%;
  margin-right: 8px;
}

.action-buttons {
  display: flex;
  justify-content: flex-start;
}

.action-buttons button {
  background-color: transparent;
  border: none;
  color: #0056b3;
  cursor: pointer;
  margin-right: 8px;
}

.delete-button:hover {
  color: #ff0000;
}

.edit-button:hover {
  color: #008000;
}

.pdf-viewer-container {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
}

.view-detail-loader .loader {
  width: 20px !important;
  height: 20px !important;
}

.new-brand-modal.pdf-view-modal .pdf-viewer-container {
  width: 100%;
  height: calc(100vh - 170px);
}
