.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../public/background.png') no-repeat center center; /* Make sure to update the path to the correct one */
  background-size: cover;
}



.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Adjust the width to make the box longer */
}

.logo {
  width: 100px; /* Adjust the width to make the logo smaller */
  margin-bottom: 20px;
}

.company-name {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 15px 0 10px; /* Increase the margin for more spacing */
}

.tagline {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px; /* Increase the margin for more spacing */
  text-align: center;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}
