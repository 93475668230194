.header-content .notification-menu {
  margin-left: auto;
  position: relative;
}

.header-content .notification-menu button {
  background: transparent;
}

.header-content .notification-menu > div {
  left: unset !important;
  right: 0 !important;
  min-width: 521px !important;
  transform: none !important;
  top: 100% !important;
  padding: 30px 7px 20px 28px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 0 34px #00000026;
  z-index: 999;
  background: #fff;
}
.header-content .notification-menu div.chakra-menu__menu-list {
  overflow-y: auto;
  max-height: 422px;
  padding-right: 24px;
}

.header-content .notification-menu > div .header-notification p.chakra-text {
  margin: 0;
  font-family: "Aeonik";
  font-size: 20px;
  font-weight: 700;
  line-height: 23.96px;
  text-align: left;
  color: #000000;
}

.header-content .notification-menu > div .header-notification > a.chakra-link {
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 700;
  line-height: 14.38px;
  text-align: right;
  height: auto;
  min-height: unset;
  text-decoration: underline;
  align-self: flex-start;
}

.header-content .notification-menu > div > div.chakra-menu__menu-list > div {
  padding: 0;
}

div.chakra-menu__menu-list::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
div.chakra-menu__menu-list::-webkit-scrollbar {
  width: 5px;
  background-color: #ebebeb;
}

div.chakra-menu__menu-list::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 999px;
}

.header-notification {
  margin: 0 0 43px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.pdf-detail img {
  min-width: 32px;
  align-self: flex-start;
  width: 32px;
}

.pdf-detail > div p.chakra-text.file-data {
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000000;
}

.pdf-detail {
  align-items: center;
  gap: 12px;
}

.pdf-detail > div p.chakra-text.file-name {
  font-family: "Aeonik";
  font-size: 15px;
  font-weight: 700;
  line-height: 13.35px;
  text-align: left;
  margin: 0;
  color: #000000;
  word-break: break-word;
}

.pdf-detail > div p.chakra-text:nth-child(2) {
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #000000;
}

.pdf-detail .progress-bar div[role="progressbar"] {
  height: 7px;
  background: #4e6bf4;
}

.chakra-stack.notifcation-wrap > div {
  justify-content: space-between;
  align-items: center;
}

.chakra-stack.notifcation-wrap > div {
  justify-content: space-between;
}

.chakra-stack.notifcation-wrap > div .pdf-status {
  text-align: right;
}

.chakra-stack.notifcation-wrap > div .pdf-status p.chakra-text {
  margin: 0;
}

.chakra-stack.notifcation-wrap > div .pdf-status p.chakra-text.time-left {
  font-family: "Aeonik";
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 14.38px;
  text-align: right;
  color: #6c6c6c;
  margin: 0 0 27px;
}

.pdf-status p.chakra-text.status-text {
  padding: 0 4px 0 0;
  font-family: "Aeonik";
  font-size: 12px;
  font-weight: 700;
  line-height: 14.38px;
  text-align: right;
  color: #4e6bf4;
}

.chakra-stack.notifcation-wrap > div .pdf-detail {
  width: 100%;
  max-width: 295px;
}

.chakra-stack.notifcation-wrap > div .pdf-detail > div {
  width: 100%;
  max-width: calc(100% - 44px);
}

.chakra-stack.notifcation-wrap > div .pdf-detail > div > .progress-bar {
  background: #ebebeb;
  border-radius: 40px;
  height: auto;
  justify-content: flex-start;
}

.pdf-detail > div p.chakra-text.file-data {
  margin: 2px 0 7px;
}

.pdf-status.ready-to-review {
  align-self: center;
}

.pdf-status.ready-to-review p.status-text {
  display: flex;
  align-items: center;
  gap: 7px;
}

.pdf-status.ready-to-review p.status-text svg path {
  stroke: #4e6bf4;
}
.pdf-status.reviewed p.chakra-text.status-text {
  font-family: "Aeonik";
  font-size: 13px;
  font-weight: 400;
  line-height: 20.45px;
  text-align: left;
  color: #00b412;
  display: flex;
  align-items: center;
  gap: 7px;
}

.pdf-status.reviewed p.chakra-text.status-text img {
  width: 26px;
  height: 26px;
  object-fit: cover;
}

.pdf-status.failed p.chakra-text.status-text {
  font-family: "Aeonik";
  font-size: 13px;
  font-weight: 400;
  line-height: 20.45px;
  text-align: left;
  color: #ff0000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chakra-stack.notifcation-wrap > div:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.pdf-detail > div p.chakra-text.file-data:last-child {
  margin-bottom: 0;
}

.pdf-status.failed p.chakra-text.status-text img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}
