/* LogoutButton.css */
.logout-button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
  }
  
  .logout-button i {
    margin-right: 10px;
  }
  
  .logout-button:hover {
    background-color: #333;
  }