@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "Aeonik";
  src: url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.eot");
  src: url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.woff2") format("woff2"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.woff") format("woff"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.ttf") format("truetype"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Bold.svg#Aeonik-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.eot");
  src: url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.woff2")
      format("woff2"),
    url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.woff")
      format("woff"),
    url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.ttf")
      format("truetype"),
    url("../public/lib/ui/assets/fonts-new/Inter18pt-Regular.svg#Inter18pt-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.eot");
  src: url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.woff2")
      format("woff2"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.woff") format("woff"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.ttf")
      format("truetype"),
    url("../public/lib/ui/assets/fonts-new/Aeonik-Regular.svg#Aeonik-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* 
@font-face {
  font-family: "Filson Pro";
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Bold.eot");
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Bold.woff2") format("woff2"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Bold.woff") format("woff"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Bold.ttf") format("truetype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Bold.svg#FilsonPro-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Filson Pro";
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Regular.eot");
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Regular.woff2") format("woff2"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Regular.woff") format("woff"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Regular.ttf")
      format("truetype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Regular.svg#FilsonPro-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Filson Pro";
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Medium.eot");
  src: url("../public/lib/ui/assets/fonts/FilsonPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Medium.woff2") format("woff2"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Medium.woff") format("woff"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Medium.ttf") format("truetype"),
    url("../public/lib/ui/assets/fonts/FilsonPro-Medium.svg#FilsonPro-Medium")
      format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Filson Pro";
  src: url("FilsonPro-BoldItalic.eot");
  src: url("FilsonPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("FilsonPro-BoldItalic.woff2") format("woff2"),
    url("FilsonPro-BoldItalic.woff") format("woff"),
    url("FilsonPro-BoldItalic.ttf") format("truetype"),
    url("FilsonPro-BoldItalic.svg#FilsonPro-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
} */

/* @font-face {
  font-family: "Filson Pro Book";
  src: url("./FilsonPro-Book.eot");
  src: url("FilsonPro-Book.eot?#iefix") format("embedded-opentype"),
    url("FilsonPro-Book.woff2") format("woff2"),
    url("FilsonPro-Book.woff") format("woff"),
    url("FilsonPro-Book.ttf") format("truetype"),
    url("FilsonPro-Book.svg#FilsonPro-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/* 

@font-face {
  font-family: "Filson Pro";
  src: url("FilsonPro-MediumItalic.eot");
  src: url("FilsonPro-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("FilsonPro-MediumItalic.woff2") format("woff2"),
    url("FilsonPro-MediumItalic.woff") format("woff"),
    url("FilsonPro-MediumItalic.ttf") format("truetype"),
    url("FilsonPro-MediumItalic.svg#FilsonPro-MediumItalic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: "Filson Pro Book";
  src: url("FilsonPro-BookItalic.eot");
  src: url("FilsonPro-BookItalic.eot?#iefix") format("embedded-opentype"),
    url("FilsonPro-BookItalic.woff2") format("woff2"),
    url("FilsonPro-BookItalic.woff") format("woff"),
    url("FilsonPro-BookItalic.ttf") format("truetype"),
    url("FilsonPro-BookItalic.svg#FilsonPro-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Filson Pro";
  src: url("FilsonPro-RegularItalic.eot");
  src: url("FilsonPro-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("FilsonPro-RegularItalic.woff2") format("woff2"),
    url("FilsonPro-RegularItalic.woff") format("woff"),
    url("FilsonPro-RegularItalic.ttf") format("truetype"),
    url("FilsonPro-RegularItalic.svg#FilsonPro-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
} */

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

.content_container {
  margin-left: auto;
  padding-top: 20px;
  max-width: calc(100% - 325px);
  padding: 100px 53px 30px;
}

h2 {
  font-family: "Aeonik";
  font-size: 21px;
  font-weight: 700;
  line-height: 25.16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000;
  margin: 0;
}

/* modal start */

.new-brand-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 97px 50px 44px;
  border-radius: 20px;
  outline: none;
  width: 90%;
  max-height: unset;
  overflow: inherit;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  min-height: unset;
  max-width: 557px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
}

.new-brand-modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #343434;
  font-family: "Aeonik";
  font-size: 23px;
  font-weight: 700;
  line-height: 36.18px;
  text-align: center;
}

.new-brand-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.new-brand-modal input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-brand-modal .modal-logo {
  position: absolute;
  top: -58px;
  width: 116px;
  height: 116px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #808080;
  border-radius: 999px;
  left: 50%;
  transform: translateX(-50%);
}

.new-brand-modal p {
  margin: 0 0 25px;
  font-family: "Aeonik";
  font-size: 23px;
  font-weight: 700;
  line-height: 36.18px;
  text-align: center;
  color: #343434;
  letter-spacing: 0;
}

.new-brand-modal .modal-buttons {
  width: 100%;
  justify-content: center;
  margin: 0;
  gap: 10px;
  display: flex;
  align-items: center;
}

.new-brand-modal .modal-buttons button {
  margin: 0;
  border-radius: 20px;
  font-family: "Aeonik";
  font-size: 17px;
  font-weight: 700;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: left;
  width: 100%;
  max-width: 189px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.new-brand-modal .modal-buttons button svg path {
  color: #fff;
}

.new-brand-modal .modal-buttons button.delete-button path {
  fill: #fff;
}

.new-brand-modal .modal-logo img {
  width: 95px;
  height: 95px;
}

.new-brand-modal .modal-buttons button.delete-button,
.save-button {
  background: #4e6bf4;
  color: #fff;
}
.new-brand-modal .modal-buttons button.delete-button:disabled,
.save-button:disabled,
.cancel-button:disabled {
  cursor: not-allowed;
}

.new-brand-modal .modal-buttons button.cancel-button {
  background: #3f3f3f;
  color: #fff;
}

.new-brand-modal .modal-buttons .delete-button:hover {
  background-color: #2d3e8e;
}

.new-brand-modal .modal-buttons .cancel-button:hover {
  background-color: #000;
}

.new-brand-modal.progress-modal {
  background: #ffffff;
  max-width: 859px;
  padding: 91px 45px 26px;
}

.new-brand-modal.progress-modal > p:last-child {
  width: 100%;
  text-align: left;
  margin: 0;
  font-family: "Aeonik";
  font-size: 16px;
  font-weight: 400;
  line-height: 25.17px;
  text-align: left;
}

.new-brand-modal.progress-modal .progress-container {
  background: #afb9e826;
  width: 100%;
  border-radius: 20px;
  padding: 30px 32px;
  display: flex;
  gap: 35px;
  align-items: center;
  margin: 0 0 13px;
}

.new-brand-modal.pdf-view-modal {
  max-width: 1139px;
  height: auto;
  padding: 68px 20px 20px;
}

.new-brand-modal.pdf-view-modal .modal-header-pdf-view {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  display: flex;
  gap: 9px;
}

.new-brand-modal.pdf-view-modal .modal-header-pdf-view button {
  background: #ededed;
  border-radius: 999px;
  width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-brand-modal.pdf-view-modal .modal-header-pdf-view button svg {
  color: #000000;
}

.new-brand-modal.pdf-view-modal .modal-header-pdf-view button:hover {
  background: #000;
}

.new-brand-modal.pdf-view-modal .modal-header-pdf-view button:hover svg {
  color: #fff;
}

.new-brand-modal.pdf-view-modal .modal-content {
  width: 100%;
  height: 100%;
}

.new-brand-modal.pdf-view-modal .modal-content iframe {
  width: 100%;
  height: 821px;
}

@media (max-height: 932px) {
  .new-brand-modal.pdf-view-modal .modal-content iframe {
    height: calc(100vh - 150px);
  }
}

/* modal ends */

.ready-to-review-popup {
  position: fixed;
  right: -100%;
  bottom: 114px;
  z-index: 10;
  box-shadow: 0 0 24px #00000026;
  width: 100%;
  max-width: 372px;
  padding: 24px 24px 22px;
  border-radius: 35px 0px 0px 35px;
  background: #fff;
  text-align: center;
  transition: all 0.3s ease;
}

.ready-to-review-popup span {
  width: 42px;
  height: 42px;
  background: #4d67e1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
}

.ready-to-review-popup span svg {
  color: #fff;
  width: 25px;
  height: 18px;
}

.ready-to-review-popup p {
  margin: 12px 0 5px;
  font-family: "Aeonik";
  font-size: 17px;
  font-weight: 700;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #000;
}

.ready-to-review-popup a {
  font-family: "Aeonik";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.77px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000;
}

.ready-to-review-popup.active {
  right: 0;
}

.content_container.editor-open .container.version-container button.save-button,
.document-viewer button.save-button {
  right: 22px;
  background: linear-gradient(
    90deg,
    rgba(78, 107, 244, 1) 0%,
    rgba(45, 62, 142, 1) 100%
  );
  border-radius: 20px;
  width: 100%;
  max-width: 303px;
  height: 42px;
  bottom: 49px;
  font-family: "Aeonik";
  font-size: 17px;
  font-weight: 700;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: center;
}

.content_container.editor-open
  .container.version-container
  button.save-button:before,
.document-viewer button.save-button:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7416 0.811523C12.0883 0.811523 12.4207 0.949228 12.6659 1.19435L14.5143 3.0428C14.7594 3.28793 14.8971 3.62038 14.8971 3.96704V13.2285C14.8971 14.3114 14.0194 15.1891 12.9366 15.1891H2.48011C1.39732 15.1891 0.519531 14.3114 0.519531 13.2285V2.77211C0.519531 1.68931 1.39732 0.811523 2.48011 0.811523H11.7416ZM2.48011 2.11858C2.11918 2.11858 1.82659 2.41118 1.82659 2.77211V13.2285C1.82659 13.5895 2.11918 13.8821 2.48011 13.8821H3.13364V9.96091C3.13364 8.87808 4.01143 8.00033 5.09422 8.00033H10.3224C11.4053 8.00033 12.283 8.87808 12.283 9.96091V13.8821H12.9366C13.2975 13.8821 13.5901 13.5895 13.5901 13.2285V4.62056C13.5901 4.27391 13.4524 3.94146 13.2072 3.69633L12.0123 2.50141C11.7672 2.25628 11.4347 2.11858 11.0881 2.11858H10.976V3.42563C10.976 4.50843 10.0982 5.38622 9.01539 5.38622H6.40128C5.31848 5.38622 4.4407 4.50843 4.4407 3.42563V2.11858H2.48011ZM10.976 13.8821V9.96091C10.976 9.59997 10.6834 9.30738 10.3224 9.30738H5.09422C4.73329 9.30738 4.4407 9.59997 4.4407 9.96091V13.8821H10.976ZM5.74775 2.11858H9.66892V3.42563C9.66892 3.78656 9.37633 4.07916 9.01539 4.07916H6.40128C6.04035 4.07916 5.74775 3.78656 5.74775 3.42563V2.11858Z' fill='white'/%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 1px;
  margin-right: 8px;
}

.document-viewer {
  position: relative;
}
.version-container .document-viewer h2 {
  margin-bottom: 20px;
}

.new-brand-modal input[type="text"] {
  height: 51px;
  outline: none;
  font-family: "Aeonik";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000;
  padding: 0 15px;
  border: 1px solid #ddd;
  background: transparent;
}

.new-brand-modal input[type="text"]::placeholder {
  color: #909090;
}

/* loader screen css */

.main-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(255 255 255, 0.7);
  backdrop-filter: blur(5px);
}

.main-loader .loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-loader .loader-wrapper img {
  animation: zoom-in-zoom-out 1.5s ease-out infinite;
}

.content_container.container-expend,
.content_container.container-expend header.top-header {
  max-width: calc(100% - 100px);
}
.top-header {
  transition: all 0.3s ease;
}

.content_container {
  transition: all 0.3s ease;
}

.new-brand-modal form {
  width: 100%;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media (max-width: 1280px) {
  .table-responsive {
    overflow-x: auto;
  }

  .table-responsive table.material-table {
    min-width: 1400px;
  }

  .material-table th:nth-child(5),
  .material-table td:nth-child(5) {
    width: 190px;
  }
}
.loading-indicator {
  padding: 10px;
}

.saveButtonWrapper {
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
}

.saveButtonWrapper > .blank_wrapper {
  width: 100%;
}

.saveButtonWrapper .btn-save-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 15px;
}

.content_container .container .document-table:last-child .table-section {
  margin: 0;
}

.new-brand-modal.reasons-modal {
  padding: 0;
  overflow: hidden;
  box-shadow: none;
  border-radius: 20px;
  max-width: 823px;
  background: #212121;
}
.new-brand-modal.reasons-modal > header {
  width: 100%;
  background: #2f2f2f;
  padding: 18px 11px 18px 36px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.new-brand-modal.reasons-modal > header > span {
  font-size: 21px;
  line-height: 25.16px;
  letter-spacing: 0.01em;
  text-align: left;
  font-family: "Aeonik";
  color: #fff;
  font-weight: 700;
}

.new-brand-modal.reasons-modal > header svg {
  width: 36px;
  height: 36px;
}

.new-brand-modal.reasons-modal > header > span.cross {
  font-size: unset;
  line-height: 0;
  background: #ededed;
  border-radius: 999px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-brand-modal.reasons-modal > header > span.cross svg {
  width: 17px;
  height: 17px;
  color: #000000;
}

.new-brand-modal.reasons-modal div > p {
  margin: 0;
  text-align: left;
}

.new-brand-modal.reasons-modal div.modal-content {
  padding: 0px 35px;
}

.new-brand-modal.reasons-modal div.modal-content h2 {
  text-align: left;
  font-family: "Courier New";
  font-size: 17px;
  font-weight: 700;
  line-height: 20.37px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0 0 13px;
  color: #fff;
}

.new-brand-modal.reasons-modal div.annotation-texts:not(:last-child) {
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.new-brand-modal.reasons-modal div.annotation-texts p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #fff;
  font-family: "Courier New";
}

.new-brand-modal.reasons-modal div.annotation-texts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  padding: 22px 0;
}

.new-brand-modal.reasons-modal div.modal-content {
  width: 100%;
}

/* @media (max-width: 1279px) {
  .content_container {
    padding: 100px 30px 50px;
  }
  .saveButtonWrapper > .blank_wrapper {
    max-width: calc(100% - 300px);
  }

  .saveButtonWrapper .btn-save-wrap {
    max-width: 300px;
  }
} */
@media (max-width: 1199px) {
  h2 {
    font-size: 18px;
  }

  .content_container {
    max-width: calc(100% - 275px);
  }
}
