.progress-container > img {
  width: 43px;
  min-width: 43px;
}

.progress-container .progress-main-container {
  width: 100%;
  max-width: calc(100% - 76px);
}

.progress-container .progress-main-container .progress-content {
  margin: 0 0 11px;
  height: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 70px;
  position: relative;
}

.progress-container .progress-main-container .progress-content .progress {
  height: 14px;
  background: linear-gradient(
    90deg,
    rgba(155, 170, 240, 1) 0%,
    rgba(84, 110, 232, 1) 100%
  );
  border-radius: 70px;
  position: relative;
}

.progress-container
  .progress-main-container
  .progress-content
  .progress
  > span.progress-icon {
  position: absolute;
  right: -5px;
  border: 4px solid #f3f5ff;
  width: 29px;
  height: 29px;
  background: #4f66d4;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-container
  .progress-main-container
  .progress-content
  .progress
  > span.progress-icon
  svg {
  width: 5px;
  height: 10px;
  color: #fff;
}

.progress-container .progress-main-container .progress-status {
  display: flex;
  justify-content: space-between;
}

.progress-container .progress-main-container .progress-status span {
  font-family: "Aeonik";
  font-size: 15px;
  font-weight: 700;
  line-height: 23.59px;
  text-align: center;
  color: #343434;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 7px;
  background: #ededed;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-btn span {
  width: 14px;
  height: 2px;
  background: #797979;
  border-radius: 3px;
}

.current-step {
  margin: 0 0 6px !important;
  text-align: start !important;
  font-size: 17px !important;
}

.current-message {
  font-size: 13px !important;
  width: 100%;
  text-align: start !important;
  color: #787878 !important;
  margin: 0 !important;
}