.top-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 54px;
  background-color: #fff;
  border-bottom: 0;
  position: fixed;
  top: 0;
  left: unset;
  right: 0;
  z-index: 999;
  height: 67px;
  width: 100%;
  max-width: calc(100% - 325px);
  box-shadow: 0 0 14px 10px #00000012;
  flex-flow: column;
}

.header-content {
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.project-title {
  display: flex;
  align-items: center;
  gap: 33px;
}

.project-title p {
  margin: 0;
  font-family: "Aeonik";
  font-size: 20px;
  font-weight: 700;
  line-height: 23.96px;
  letter-spacing: 0.01em;
  text-align: left;
}

.project-title svg {
  width: 29px;
  height: 29px;
  color: #000000;
  fill: #000000;
}

.user-name {
  display: flex;
  gap: 0;
  align-items: center;
}

.user-name img {
  margin: 0 8px 0 21px;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 999px;
}

.user-name span {
  font-family: "Aeonik";
  font-size: 15px;
  font-weight: 400;
  line-height: 17.97px;
  letter-spacing: 0.01em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 17px;
  color: #000;
}

.user-name span svg {
  width: 15px;
  color: #6f6f6f;
}
.user-menu {
  display: flex;
  align-items: center;
}

.user-menu .dropdown {
  margin: 0 0 0 17px;
  height: auto;
}

.user-menu .dropdown button.dropdown-toggle {
  padding: 0;
  display: flex;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  height: 30px;
  border-radius: 4px;
  background-color: white;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 2px;
  color: #6f6f6f;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  min-width: 140px;
  padding: 5px 5px;
  margin-top: 12px;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: black; /* Set text color to black */
}

.dropdown-menu button:hover {
  background-color: #f3f3f3;
}

.dropdown-toggle:hover {
  background: transparent;
}

.bell-icon {
  width: 36px;
  height: 36px;
  border: 1px solid #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
}

.bell-icon svg {
  width: 16px;
  height: 19px;
  color: #4e6bf4;
  position: relative;
  left: 0;
}

.header-content .notification-menu button[data-active] > div {
  background: #4e6bf4;
  border-color: #4e6bf4;
}

.header-content .notification-menu button[data-active] > div svg path {
  stroke: #fff;
}

.bell-icon span.badge {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #4e6bf4;
  top: -5px;
  right: -5px;
  border-radius: 100%;
  font-family: "Aeonik";
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.header-content .notification-menu {
  margin-left: auto;
}

.header-content .notification-menu button {
  background: transparent;
}

.project-title span {
  display: flex;
  transition: all 0.3s ease;
}

.project-title span:not(.side-open) {
  transform: scaleX(-1);
}

@media (max-width: 1365px) {
  .top-header {
    padding: 15px 30px;
  }
}
@media (max-width: 1199px) {
  .top-header {
    max-width: calc(100% - 275px);
  }
}
